(function ($) {
  var referrer = document.referrer;
  if (referrer !== '') {
    var referrers = $('body').data('brand-referrers');
    var brandName = '';
    $.each(referrers, function (i, item) {
      if (referrer.indexOf(item.BrandReferrer) !== -1) {
        brandName = item.BrandName;
        return false;
      }
    });
    if (brandName !== '') {
      var sitecoreItem = $("body").data("sc-item");
      if (cookieHelper.getCookie('brandNotification_' + brandName) !== '1') {
        $.get("/get-brandnotification?itemId=" + sitecoreItem + "&referrer=" + brandName, function (data) {
          $('.navigationMainComponent').append($(data));
          $(".brandTransitionNotificationComponent").slideDown("fast");
          $(".brandTransitionNotificationComponent").delay(10000).slideUp("fast");
          cookieHelper.setCookie('brandNotification_' + brandName, "1", 365 * 100);
          $('.brandTransitionNotificationComponent-btnClose').on('click', function () {
            $('.brandTransitionNotificationComponent').hide();
          });
        });
      }
    }
  }
})(jQuery);